import { useState } from 'react';
import './gift.css';

export default function Gift() {
    const [clicked, setClicked] = useState<boolean>(false);

    return (
        <div className={["gift_overlay", clicked ? 'hidden' : ''].join(' ')} onClick={() => setClicked(true)}>
            <img className="gift" src="https://static.vecteezy.com/system/resources/thumbnails/035/566/895/small/red-gift-box-and-gold-ribbon-chinese-new-year-elements-icon-3d-rendering-png.png" />
        </div>
    );
}