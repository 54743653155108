import React from 'react';
import logo from './logo.svg';
import './App.css';
import Card from './card';
import Gift from './gift';

function App() {
  return (
    <div className="App">
      <Gift />
      <div className="heino">
        <h2>Tillykke med de 30 år!</h2>
        <img id='image' src="https://cdn-play.tv2i.dk/6a235dbd-4185-4efc-bd02-437c2724853d.jpg?rect=380%2C0%2C1311%2C1124&w=1920&dpr=0.5&auto=format%2Ccompress&fm=webp" alt="Heino Hansen" />
        <p>Sæt kryds i kalenderen den</p>
        <h3>5. April 2025</h3>
        <div className='gift_list'>
          <p><span id='bold'>4 x</span> Billetter til Boullion i København</p>
          <p><span id='bold'>4 x</span> Billetter til Heino Hansen</p>
        </div>
        {/* <p>Din gave er at vi (Dig, Nicolai, Thor og Clara) skal se Heino Hansen og spise på Boulion i københavn d. 5. April</p> */}
      </div>
    </div>
  );
}

export default App;
